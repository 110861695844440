import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <>
    <h2>Panchito Salceda</h2>
    <h2>Ilustraciones</h2>
    <h3>Patrimonio historico</h3>
    <h3>Arquitectura Mexicana</h3>
    <h3>Sketch urbano</h3>
    <h2>Desarrollo web</h2>
    <h3>Sitios web</h3>
    <h3>Front-End</h3>
    <h4>React</h4>
    <h4>Astro</h4>
    <h3>Back-End</h3>
    <h4>Laravel</h4>
    <h4>CodeIgniter</h4>
    </>
  );
}

export default App;
